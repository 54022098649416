$primary: #007bff;
$primary-medium: #007bffbf;
$primary-light: #70a3c31f;
$primary-light-solid: aliceblue;
$secondary: #7ddefb;
$secondary-light-2: #7ddefb97;
$secondary-light: #f0f8ff;
$secondary-light-3: #a7deef97;
$site-bg-color: #f8f7fc;
$site-fg-color: white;

$positive-color: green;
$positive-color-medium: #008000a6;
$positive-color-light: #00800054;
$negative-color: #e44e4e;
$negative-color-dark: orangered;
$negative-color-light: #e44e4e4a;
$warning-color: orange;
$error-color: #e44e4e;
$box-shadow-color: #eee;
$mild-border-color: #d6d7d88a;
$grey: #9b9b9b;
$grey-light: #ccc;
$default-font-color: darkslategray;
$muted-text-color: #6c757dbd;
$highlight-color: #ffed8d;
$highlight-color-light: #f1da8429;
$black-lighten: lighten(black, 30%);
$whatsapp-color: #25d366;

$invoice-color: #71ce41;
$delivery-chalan-color: #71ce41;
$quotation-color: deepskyblue;
$purchase-order-color: orange;
$creditnote-color: #e44e4e;

/**** Default Values ***/
$link-color:#007bff;

/**** screen sizes ***/
// Mobile
$mobile-screen-max-width: 480px;
// Tablet & Ipads
$tablet-screen-min-width: 481px;
$tablet-screen-max-width: 768px;
// Desktop
$desktop-screen-min-width: 1025px;
$desktop-screen-max-width: 1280px;
// Large Desktop
$large-desktop-screen-min-width: 1281px;

/*** Indexes ***/
$menu-zindex: 800;
$fixed-item-zindex: 700;
$modal-zindex: 900;

// Font Sizes
$small-font-size: 0.85rem;
$default-font-size: 1rem;
$medium-font-size: 1.25rem;
$title-font-size: 1.5rem;

// Icon Sizes
$small-icon-size: 1rem;
$medium-icon-size: 1.5rem;
$large-icon-size: 2rem;
$xlarge-icon-size: 3rem;

// Form Control Size
$form-control-m: 10rem;
$form-control-l: 20rem;
$mutext-text-m: 0.85rem;

// styles
$box-shadow: 7px 7px 7px $box-shadow-color;
$box-shadow-border: 0.2px solid $mild-border-color;
