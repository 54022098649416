/*** Form ***/
@import "variables";

.form-container {
  display: flex;
  flex-flow: column nowrap;
}

.form-group {
  position: relative;
  top: 0;
  padding: 1rem 1rem;
  margin-bottom: 0.5rem;
  text-align: left;
  width: fit-content;
  z-index: 0;

  .ng-touched.ng-invalid {
    border-image: linear-gradient(to right, $negative-color, $negative-color-dark);
    border-image-slice: 1;
  }

  .ng-touched.ng-valid {
    border-image: linear-gradient(to right, $primary, $secondary);
    border-image-slice: 1;
  }
}

select.form-control {
  //padding: 0.5rem 0 0.5rem 0;
  height: calc(2.5rem + 2px) !important;
}

.form-control {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid lighten($grey, 15%);
  border-radius: 0;
  outline: 0;
  font-size: 1.2rem;
  color: black;
  padding: 5px 0;
  background-color: transparent !important;
  transition: border-color 0.2s;
  position: relative;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown~.form__label {
    font-size: 1rem;
    cursor: text;
    top: 2rem;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 0.85rem;
  color: $grey;
  width: max-content;
  z-index: -1;

  &:hover,
  &:active,
  &:focus {
    font-size: 1rem;
    cursor: text;
    top: 2rem;
  }
}

// .modalBody {
//   .form-control {
//     z-index: 201;
//   }

//   .form__label {
//     z-index: 200;
//   }
// }

.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus,
.form-control:-webkit-autofill:active,
.form-control:hover,
.form-control:focus {
  ~.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 0.85rem;
    color: $primary;
    // z-index: -1;
  }

  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  // padding-bottom: 1rem;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary, $secondary);
  border-image-slice: 1;
}

/* reset input */
.form-control {

  &:required,
  &:invalid {
    box-shadow: none;
  }
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus,
.form-control:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-control:disabled {
  background-color: transparent !important;
  pointer-events: none;
}

option:disabled {
  color: $grey-light;
}

.form-control-sm {
  max-width: 5rem;
}

.form-control-m {
  width: $form-control-m;
}

.form-control-l {
  width: $form-control-l;
}

.auto-fill-container {
  background-color: white;
  border: 1px solid #ccc;
  padding: 0.25rem;
  box-shadow: 3px 3px 3px #ccc;
  cursor: pointer;
}

.auto-fill-item {
  border-bottom: 0.5px solid #ccc;

  &:hover {
    background-color: $secondary-light;
  }
}

// For Large Screens
@media (min-width: 750px) {
  .form-container {
    align-items: center;
    min-width: 35vw;

    form {
      width: fit-content;
    }
  }
}

.input-size-auto {
  width: auto;
}

.input-xsm {
  width: 4rem;
}

.input-xs {
  width: 5rem;
}

.input-sm {
  width: 8rem;
}

.input-md {
  width: 12rem;
}

.input-xmd {
  width: 16rem;
}

input:read-only {
  border: none;
  font-weight: 500;
}

.activeRadioBox {
  border: 1px solid $primary;
  padding: 0.25rem;
  background-color: $secondary-light;
  border-radius: 5px;
  font-weight: bold;
}

.input-help-icon {
  float: right;
  margin: -1.25rem;
  color: $grey;
  cursor: pointer;
}

.required-asterisk {
  color: red;
  font-weight: bold;
  font-size: 1.2em;
  position: absolute;
  top: 2rem;
  right: 0;
}

.form-check-label {
  .required-asterisk {
    top: 0rem;
  }
}

.form-check {
  padding: 0 1.25rem;
}

mark {
  background-color: $highlight-color !important;
  padding: 0 !important;
}

.dropdownResult {
  position: absolute;
  z-index: 2;
  margin-top: 3rem;
  max-height: 20rem;
  background: white;
  overflow-y: auto;
  box-shadow: $box-shadow;
  border: $box-shadow-border;
  border-radius: 7px;
}

.dropdownResultItem {
  cursor: pointer;
  padding: 1rem;
  background-color: white;

  &:focus,
  &:hover {
    background-color: $primary-light;
    font-weight: 500;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
}

select:disabled {
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  color: $muted-text-color;
  height: auto !important;
}

.section-separator {
  text-align: center;
  border-bottom: 1px dotted black;
}

.radio-group {
  display: flex;
  flex-flow: row wrap;
}

.radio-option-item {
  margin-right: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  min-width: 4rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.radio-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

hr {
  border-top: 1px solid $primary-medium;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.warnings-table {
  margin: 0;
  width: fit-content;
}

fieldset {
  padding: 1rem;
  margin: 1rem;
  width: fit-content;

  legend {
    color: $grey;
    display: flex;
    width: auto;
    font-size: 1.2rem;
    background: $primary-light-solid;
    padding: 0 1rem;
    border-radius: 5px;
  }
}

.fieldset-auto {
  width: auto;
}

.fieldset-sm {
  width: 20rem;
}

.fieldset-md {
  width: 25rem;
}

.content-container {
  width: fit-content;
  min-width: 25vw;
}

.font-weight-lighter {
  font-weight: lighter;
}

.text-control {
  padding-right: 1em;
}

.btn-align {
  position: absolute;
  left: 80%;
  top: 0;

  // Non-Mobile
  @media (min-width: $mobile-screen-max-width) {
    margin: 23px 48px;
  }

  //For Mobile
  @media (max-width: $mobile-screen-max-width) {
    margin: 23px 36px;
  }
}