@font-face {
  font-family: 'bupicon';
  src:  url('/assets/fonts/bupicon.eot?68z8v1');
  src:  url('/assets/fonts/bupicon.eot?68z8v1#iefix') format('embedded-opentype'),
    url('/assets/fonts/bupicon.ttf?68z8v1') format('truetype'),
    url('/assets/fonts/bupicon.woff?68z8v1') format('woff'),
    url('/assets/fonts/bupicon.svg?68z8v1#bupicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bupicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Add:before {
  content: "\e900";
  color: inherit;
}
.icon-Announce:before {
  content: "\e901";
  color: inherit;
}
.icon-Balance:before {
  content: "\e902";
  color: inherit;
}
.icon-Bank:before {
  content: "\e903";
  color: inherit;
}
.icon-Banner:before {
  content: "\e904";
}
.icon-Category:before {
  content: "\e905";
  color: inherit;
}
.icon-Close-Circle:before {
  content: "\e906";
}
.icon-Dashboard:before {
  content: "\e907";
  color: inherit;
}
.icon-Delete:before {
  content: "\e908";
  color: inherit;
}
.icon-Delivery:before {
  content: "\e909";
}
.icon-Filter:before {
  content: "\e90a";
  color: inherit;
}
.icon-Finance:before {
  content: "\e90b";
}
.icon-Items:before {
  content: "\e90c";
}
.icon-Low-stock:before {
  content: "\e90d";
  color: inherit;
}
.icon-Menu:before {
  content: "\e90e";
  color: inherit;
}
.icon-Message:before {
  content: "\e90f";
}
.icon-Money:before {
  content: "\e910";
}
.icon-Notification:before {
  content: "\e911";
  color: inherit;
}
.icon-Orders:before {
  content: "\e912";
  color: inherit;
}
.icon-Out-Of-Stock:before {
  content: "\e913";
  color: inherit;
}
.icon-Parties:before {
  content: "\e914";
}
.icon-Payment-Pending:before {
  content: "\e915";
}
.icon-Payment:before {
  content: "\e916";
}
.icon-Route:before {
  content: "\e917";
  color: inherit;
}
.icon-Sales-overview:before {
  content: "\e918";
  color: inherit;
}
.icon-Search:before {
  content: "\e919";
}
.icon-Setting:before {
  content: "\e91a";
  color: inherit;
}
.icon-Site:before {
  content: "\e91b";
}
.icon-Stock-overview:before {
  content: "\e91c";
}
.icon-Upload-Cloud:before {
  content: "\e91d";
}
