@import "variables";

.clearfix {
  clear: both;
}

.fitWidth {
  width: fit-content;
}

/*** Font Styles -- START ***/
.font-lg {
  font-size: 1.1rem;
}

.font-lgr {
  font-size: larger;
}

.font-sm {
  font-size: small;
}

.font-smaller {
  font-size: smaller;
}

.font-xsm {
  font-size: 0.85rem;
}
/*** Font Styles -- END ***/

/*** Icon Styles -- Start ***/

.icon-sm {
  font-size: $small-icon-size !important;
}

.icon-m {
  font-size: $medium-icon-size !important;
}

.icon-l {
  font-size: $large-icon-size !important;
}

.icon-xl {
  font-size: $xlarge-icon-size !important;
}

.muted-icon {
  color: $grey;
}
.negativeText {
  color: $negative-color-dark;
}
.negativeText-sm {
  color: $negative-color-dark;
  font-size: 0.85rem;
}

.negativeIconMd {
  font-size: 1.5rem;
  color: $negative-color-dark;
  cursor: pointer;
}

.negativeIcon-sm {
  font-size: 1rem;
  color: $negative-color-dark !important;
  cursor: pointer;
}

.negativeIcon {
  font-size: 1.2rem;
  color: $negative-color-dark !important;
  cursor: pointer;
}

.negativeIcon-xsm {
  font-size: 0.75rem;
  color: $negative-color-dark !important;
  cursor: pointer;
}

.positiveText {
  color: $positive-color-medium;
}

.positiveIcon {
  font-size: 1.2rem;
  color: $positive-color-medium !important;
  cursor: pointer;
}

.positiveIcon-sm {
  font-size: 1rem;
  color: $positive-color-medium !important;
  cursor: pointer;
}

.positiveIcon-xsm {
  font-size: 0.75rem;
  color: $positive-color-medium !important;
}

.warning-icon {
  color: $warning-color;
  font-size: 1.2rem;
}

.icon-inside-left-parent {
  position: absolute;
  right: 0.75rem;
  margin-top: 1rem;
  z-index: 2;
}

.field-icon {
  height: 1.5rem;
}

.mt-2r {
  margin-top: 2rem;
}

/*** Icon Styles -- END  ***/

/*** Display -- START ***/

.top-right-cornered {
  position: absolute;
  right: 0;
  top: 0;
}

.right-bottom-cornered {
  position: absolute;
  right: 0;
  bottom: 0;
}

.top-left-cornered {
  position: absolute;
  left: 0;
  top: 0;
  margin-left: 0.5rem;
}

.postion-relative {
  position: relative;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*** Display -- END ***/

/*** Flex Classes -- START ***/

.flxRowSt {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}

.flxRowSE {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.flxRowSB {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.flxRowEnd {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.flxRowNwSt {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.flxRowNwEnd {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.flxRowNwSB {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.flxRowNwBL {
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
}

.flxRowNWCntr {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.flxRowWCntr {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.flxColSt {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
}

.flxColWrapCntr {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

.flxColCntr {
  display: flex;
  justify-content: center;
}

.flxColStNw {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.flxColNWSB {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.flxColNWEnd {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

.flxRowAlignCenter {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.flxRowWAlignCenter {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.flxRowAlignBaseline {
  display: flex;
  flex-flow: row;
  align-items: baseline;
}

.flxRowCntr {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.flxColNwAlignCenter {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-end {
  align-items: flex-end;
}

.flx1 {
  flex: 1;
}

.flx20 {
  flex: 20;
}

.flx40 {
  flex: 40;
}
.flx60 {
  flex: 60;
}
.flx80 {
  flex: 80;
}

/*** Flex Classes -- END ***/

/**** Form -- START ****/
.form-group-sm {
  .form-control {
    font-size: 0.8rem !important;
  }
  .form__label {
    font-size: 0.75rem !important;
  }
}

.text-muted {
  font-size: $default-font-size;
  color: $muted-text-color !important;
}

.text-muted-xsm {
  font-size: 0.85rem !important;
  color: $muted-text-color !important;
}

.text-xsm {
  font-size: 0.85rem !important;
}

/*** Images -- START ***/
.img-xsm {
  height: 1.5rem;
}

.img-sm {
  height: 3rem;
}

.img-m {
  height: 6rem;
}

.img-w-m {
  width: 10rem;
}

.img-l {
  height: 12rem;
}

.img-xm {
  height: 9rem;
}

.img-full {
  height: 100vh;
}

.plr-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.mlr-1 {
  margin-left: 1rem;
  margin-right: 1rem;
}

/*** Images -- END ***/

/*** Others -- START ***/
.not-navigable {
  cursor: not-allowed !important;
  pointer-events: none;
}

.overflow-auto {
  overflow: auto !important;
}

.shadow-box-on-hover {
  &:hover {
    box-shadow: $box-shadow;
    border: $box-shadow-border;
    border-radius: 3px;
    padding: 0.25rem;
  }
}

.shadowed-box {
  padding: 1rem;
  box-shadow: $box-shadow;
  border: $box-shadow-border;
  border-radius: 8px;
}

.rounded-border-5 {
  border-radius: 5px;
}

.show-on-hover-item {
  display: none;
}

.show-on-hover-container:hover .show-on-hover-item {
  display: block;
}

.linkeffect-on-hover {
  
}

@media only screen and (min-width: 600px) {
  // .shadowed-box-non-mobile {
  //   padding: 3rem;
  //   box-shadow: $box-shadow;
  //   border: $box-shadow-border;
  //   border-radius: 8px;
  // }
}

.hide {
  display: none;
}

.pointer-allow {
  pointer-events: unset !important;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-nodrop {
  cursor: no-drop;
}
.box-shadow {
  box-shadow: $box-shadow;
  background: $site-fg-color;
}

.box-shadow-border {
  border: $box-shadow-border;
  border-radius: 7px;
}

.borderless td,
.borderless th {
  border: none !important;
}

.background-less {
  background: none !important;
}

.topRightAbsolute {
  position: absolute;
  top: 0;
  right: 0;
}

.topCenterFixed {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: $fixed-item-zindex;
  transform: translate(-50%, -50%);
}

.fixed-bottom-left {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $fixed-item-zindex;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.mild-border {
  border: 1px solid $mild-border-color;
}

.positive-color {
  color: $positive-color;
}

.positive-color-light {
  color: $positive-color-medium;
}

.negative-color {
  color: $negative-color;
}

.warning-color {
  color: $warning-color;
}

.word-break-all {
  word-break: break-all;
}

.bg-light {
  background: #f0f8ff6b  !important;
}

.popupPostion {
  z-index: 1; 
  position: relative;
}
/*** Others -- END ***/
