/* You can add global styles to this file, and also import other style files */
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "~@ng-select/ng-select/themes/material.theme.css";
@import "variables";
@import "utilities";
@import "form";

html {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

body {
  font-family: "Sora", sans-serif;
  color: $default-font-color;
}

button {
  margin: 1rem;
}

a {
  color: $link-color !important;
  text-decoration: none !important;
  background-color: transparent !important;
  cursor: pointer;

  &:hover {
    color: $link-color !important;
    text-decoration: underline !important;    
  }

  .fa,
  .fas,
  .far,
  .fa-solid {
    color: $muted-text-color;
  }
}

.fa,
.fas,
.far,
.fa-solid {
  font-family: "Font Awesome 6 Free" !important;
  cursor: pointer;
}

i {
  cursor: pointer;
  padding: 0.5rem;

  &.action-icon {
    transition: all 0.25s ease;

    &:hover {
      border-radius: 50%;
      background: aliceblue;
      color: black;
      box-shadow: 2px 2px 2px $mild-border-color;
    }
  }
}

.imagePreview {
  border: 0.5px solid #ccc;
  padding: 0.5rem;
  box-shadow: 3px 3px 8px #ccc;
  margin-right: 0.5rem;
}

.imagePreview img {
  max-height: 8rem;
  max-width: 8rem;
}

.page-center {
  margin-top: 10rem;
}

.msgBox {
  padding: 1rem;
  border: 1px solid #cccccc96;
  border-radius: 5px;
}

.msgBox-sm-light {
  border: 1px solid #cccccc96;
  opacity: 0.5;
}

.msg-warning {
  background-color: #ffff0045;
  color: black;
}

.emptyButton {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

.table thead {
  line-height: 2.5rem !important;
}

.table-container {
  border: 1px solid #ccc;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $secondary-light;
}

.table .table-bordered td {
  border-bottom: none !important;
  border-top: none !important;
  border-left: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
  padding: 1rem;
}

.table .table-bordered tr {
  border: 1px solid #dee2e6 !important;
}

.table td {
  vertical-align: middle;
}

.card-header {
  background-color: #70a3c31f !important;
}

.pad-left-right-sm {
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn {
  border-radius: 0.25rem;
  box-shadow: $box-shadow;
  border: 1px solid;
}

.btn:disabled {
  background-color: #ccc !important;
  border-color: #ccc;
  color: #00000073;
}

.btn-outline-info {
  color: $primary-medium;
  background-color: transparent;
  background-image: none;
  border-color: $primary-medium;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}

// .btn-primary {
//   background-color: $primary !important;
//   border-color: $primary !important;
// }

.fa-inactive {
  opacity: 0.6;
}

.fa-disabled {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.fa-whatsapp {
  color: $whatsapp-color;
}

.gradient-text {
  background: linear-gradient(to right, $primary, $secondary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.page-title {
  h4 {
    width: fit-content;
    padding: 0.5rem;
    margin: 0;
    color: $default-font-color;
    font-size: $title-font-size;
    letter-spacing: 1px !important;
  }
}

section {
  padding: 1rem;
  // background-color: $site-fg-color;
  // box-shadow: $box-shadow;
  // border: $box-shadow-border;
  // border-radius: 7px;
}

.section-title {
  margin-left: 5px;
  h4 {
    background-color: $primary-medium;
    width: fit-content;
    padding: 0.5rem;
    border-radius: 5px 5px 0px 0px;
    margin: 0;
    color: white;
    font-size: 1.2rem;
  }
}

.section-content {
  padding: 1rem;
  background-color: $site-fg-color;
}

.section-content-border {
  border-top: 0.25rem solid $primary-medium;
}

.show-more-button {
  width: 100%;
  text-align: center;
  background-color: aliceblue;
}

.profile-img-md {
  height: 4rem;
  // border-radius: 50%;
  // border: 1px solid #ccc;
  // width: 4rem;
}

.table-borderless td,
.table-borderless th {
  border: none;
}

.table-sm {
  td {
    padding: 0 0.5rem 0 0.5rem;
  }
}

.HOLD,
.CANCELLED,
.FAILED,
.REFUNDED {
  color: $negative-color-dark;
}

.AWAITING_PAYMENT,
.PAYMENT_PENDING {
  color: $warning-color;
}

.PARTIAL {
  color: $positive-color-medium;
}

.SUCCESS,
.COMPLETED {
  color: $positive-color;
}

.OUTWARD {
  background-color: $negative-color-light;
  color: black;
}

.INWARD {
  background-color: $positive-color-light;
  color: black;
}


.qrImg-sm {
  height: 5rem;
}

.content-sm {
  max-width: 8rem;
  word-break: break-all;
}

.table {
  margin: 0;
}

.table thead th {
  color: black;
  letter-spacing: 0.75px;
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-29 {
  width: 29%;
}

.menu-ul-list {
  list-style-type: none !important;
  padding: 1rem !important;
  margin: 0 !important ;

  li {
    cursor: pointer;
    padding: 0.5rem 0.5rem;

    &:hover {
      background-color: $primary-light-solid;

      .form__label {
        z-index: 0;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px dashed $grey-light;
    }
  }
}

.tab-title-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  .tab-title {
    width: fit-content;
    padding: 0.5rem;
    border-radius: 7px 7px 0px 0px;
    margin: 0;
    color: white;
    font-size: 1.2rem;
    margin-right: 0.15rem;
    background-color: $primary-light-solid;
    color: $grey;

    &:hover,
    &.active {
      background-color: $primary-medium;
      color: white;
      cursor: pointer;
    }
  }
}

.highlight {
  background-color: $highlight-color;
  color: $black-lighten;
}

/*** Navigation ****/

.nav-link {
  cursor: pointer;
}

.avatar-img {
  border-radius: 50%;
  width: 3rem;
  border: 1px solid $grey-light;
}

.icon-menu {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  padding-right: 1rem;
  color: slategray;

  i {
    font-size: $medium-icon-size;
  }

  .icon-text {
    font-size: $small-font-size;
  }
}

.icon {
  padding: 1rem;
  color: $primary-medium;
  border-radius: 7px;
  background: $secondary-light;
}

.close-icon {
  background-color: unset !important;
  color: $grey !important;
}

/*** ng select overrides ***/
ng-select {
  min-width: 16rem;
  .ng-select-container {
    align-items: flex-end !important;
  }
}

ng-dropdown-panel {
  margin-top: -1rem !important;
}
/*** ng select overrides -End ***/

/*** Animations ***/
.radiating-gradient {
  background: linear-gradient(206deg, $primary, $primary-medium, $secondary-light-2, $secondary);
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 3s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

::-webkit-scrollbar {
  width: 10px;
  background-color: white;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: $secondary-light-3;
  border-radius: 7px;
}

//For Mobile
@media (max-width: $mobile-screen-max-width) {
  html {
    font-size: 12px;
  }

  .show-only-on-non-mobile {
    display: none;
  }
}

// Non-Mobile
@media (min-width: $mobile-screen-max-width) {
  html {
    font-size: 12px;
  }

  .show-only-on-mobile {
    display: none;
  }

  .toggle-align {
    margin-top: 1.5rem;
  }
}

.background-image{
  height:45em; 
  background-size:cover; 
  width:auto;
  background-image: url('../../images/backgound.svg');
  background-position:50% 100%;
}
